.client-carousel {
	padding-top: 80px;
	padding-bottom: 80px;

	.item {
		padding: 30px;
		transition: all 500ms ease;

		&:hover {
			background-color: $cretech-gray;
		}
	}
}

.client-carousel img {
	transition: 500ms;
	opacity: 0.3;
	max-width: 100%;
}

.client-carousel img:hover {
	opacity: 0.6;
}


.client-carousel--two {
	padding: 0;

	.owl-nav {
		position: absolute;
		top: 0%;
		left: 50%;
		transform: translate(-50%, -50%);
		background-color: #fff;
		padding-left: 26px;
		padding-right: 26px;
		display: flex;
		align-items: center;
		justify-content: center;

		button {
			border: none;
			outline: none;
			width: 26px;
			height: 26px;
			background-color: $cretech-gray !important;
			display: flex;
			justify-content: center;
			align-items: center;
			color: $cretech-black;
			font-size: 12px !important;
			transition: all 500ms ease;

			+button {
				margin-left: 5px;
			}

			&:hover {
				background-color: $cretech-primary !important;
				color: #fff;
			}
		}
	}

	.owl-stage-outer {
		padding-top: 80px;
		padding-bottom: 80px;
		border-top: 1px solid $cretech-border;

	}
}

.client-carousel--home-two {
	border-top: 1px solid $cretech-border;

	.owl-stage-outer {
		border: 0;
	}
}