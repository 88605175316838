.search-popup {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	padding-left: 20px;
	padding-right: 20px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-transform: translateY(-110%);
	transform: translateY(-110%);
	-webkit-transform-origin: left center;
	transform-origin: left center;
	-webkit-transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
	transition: visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
	transition: transform 500ms ease 500ms, visibility 500ms ease 500ms;
	transition: transform 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
	visibility: hidden;
}

.search-popup.active {
	opacity: 1;
	-webkit-transform: translateY(0%);
	transform: translateY(0%);
	visibility: visible;
	-webkit-transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: transform 500ms ease 0ms, visibility 500ms ease 0ms;
	transition: transform 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.search-popup__overlay {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #000000;
	opacity: 0.9;
	cursor: pointer;
}

.search-popup__content {
	width: 100%;
	max-width: 560px;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: translateY(-130%);
	transform: translateY(-130%);
	-webkit-transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms;
	transition: opacity 500ms ease 0ms, visibility 500ms ease 0ms, transform 500ms ease 0ms, -webkit-transform 500ms ease 0ms;
}

.search-popup.active .search-popup__content {
	opacity: 1;
	visibility: visible;
	-webkit-transform: translateY(0);
	transform: translateY(0);
	-webkit-transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms;
	transition: opacity 500ms ease 500ms, visibility 500ms ease 500ms, transform 500ms ease 500ms, -webkit-transform 500ms ease 500ms;
}

.search-popup__content form {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	position: relative;
	background-color: #fff;
	overflow: hidden;
}

.search-popup__content form input[type="search"],
.search-popup__content form input[type="text"] {
	width: 100%;
	background-color: #fff;
	font-size: 16px;
	border: none;
	outline: none;
	height: 66px;
	padding-left: 30px;
}

.search-popup__content .thm-btn {
	padding: 0;
	width: 68px;
	height: 68px;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	text-align: center;
	position: absolute;
	top: 0;
	right: -1px;
	border-radius: 0;
	background-color: $cretech-primary;
	border: 0;
}

.search-popup__content .thm-btn i {
	background-color: transparent;
	margin-left: 0;
}

.search-popup__content .thm-btn:hover {
	background-color: $cretech-secondary;
}