.about-one {
	&__image {
		position: relative;

		@media (min-width: 1200px) {
			margin-top: -35px;
			margin-bottom: -50px;
		}

		&::before {
			content: '';
			width: 490px;
			height: 490px;
			background-color: $cretech-gray;
			position: absolute;
			top: 50px;
			left: -90px;
			border-radius: 50%;
			display: none;

			@media (min-width: 992px) {
				display: block;
			}
		}

		&::after {
			content: '';
			width: 208px;
			height: 208px;
			@include cretech-gradient;
			position: absolute;
			bottom: 50px;
			left: 120px;
			border-radius: 50%;
			display: none;

			@media (min-width: 992px) {
				display: block;
			}
		}

		img {
			max-width: 100%;
			position: relative;
			z-index: 10;

			@media (min-width: 1200px) {
				margin-left: 70px;

			}
		}
	}

	&__text {
		margin-top: -20px;
	}

	&__list {
		@include reset-ul;
		margin-top: 20px;
		margin-bottom: 30px;

		@media (min-width: 1200px) {
			margin-top: 37px;
			margin-bottom: 40px;
		}

		li {
			position: relative;
			font-size: 18px;
			font-weight: bold;
			color: $cretech-black;
			line-height: 1.7em;
			padding-left: 30px;

			+li {
				margin-top: 5px;
			}

			>i {
				position: absolute;
				top: 8px;
				left: 0;
				font-size: 16px;
				color: $cretech-secondary;
			}
		}
	}

	&__content {
		@media (min-width: 1200px) {
			padding-left: 70px;
		}
	}
}

.about-two {
	background-image: url(../images/shapes/about-two-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: top right;

	&__image {
		display: inline-block;
		position: relative;

		@media (min-width: 1200px) {
			right: 20px;
			margin-top: 15px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include cretech-gradient();
			transform: rotate(3deg);
		}

		img {
			max-width: 100%;
			position: relative;

			@media (min-width: 1200px) {
				max-width: none;
			}
		}
	}

	.section-title {
		padding-bottom: 30px;
	}

	&__list {
		@include reset-ul;
		counter-reset: aboutTwoNumber;
		margin-bottom: 50px;
		margin-top: 40px;

		@media (min-width: 768px) {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			grid-column-gap: 30px;
		}

		li {
			counter-increment: aboutTwoNumber;
			position: relative;
			display: flex;
			align-items: center;
			font-size: 20px;
			font-weight: bold;
			letter-spacing: -0.05em;
			line-height: 1.2em;
			color: $cretech-black;

			+li {
				margin-top: 30px;

				@media (min-width: 768px) {
					margin-top: 0;
				}
			}

			&::before {
				content: counters(aboutTwoNumber, ".", decimal-leading-zero);
				width: 58px;
				height: 58px;
				background-color: $cretech-secondary;
				margin-right: 20px;
				border-radius: 50%;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #fff;
				letter-spacing: 0;
				flex-shrink: 0;
			}
		}
	}

	&__content {
		@media (min-width: 1200px) {
			padding-right: 100px;
		}
	}
}


.about-three {
	position: relative;

	&__shape {
		position: absolute;
		top: 0;
		right: 0;
		width: 100%;
		height: 685px;
		background-repeat: no-repeat;
		background-position: top right;
	}

	&::before {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-image: url(../images/shapes/about-three-bg-1-1.png);
		background-position: left center;
		background-repeat: no-repeat;
		z-index: 20;
		display: none;

		@media (min-width: 1200px) {
			display: block;
		}
	}



	&__image {
		position: relative;
		display: inline-block;

		&::before {
			position: absolute;
			content: '';
			width: 10000px;
			right: 51%;
			background-color: $cretech-primary;
			top: -120px;
			bottom: -120px;
			display: none;

			@media (min-width: 992px) {
				display: block;
			}
		}

		&::after {
			content: '';
			background-color: $cretech-secondary;
			position: absolute;
			right: calc(51% - 39px);
			bottom: 100%;
			width: 19px;
			height: 90px;
		}

		img {
			position: relative;
			max-width: 100%;
		}
	}

	&--home-one &__image {
		&::before {
			bottom: 0;
		}
	}

	&__text {
		margin-top: -20px;
		margin-bottom: 35px;
	}

	&__list {
		@include reset-ul;

		&__item {
			@media (min-width: 768px) {
				display: flex;
				align-items: flex-start;
			}
		}

		&__item+&__item {
			margin-top: 20px;

			@media (min-width: 768px) {
				margin-top: 35px;
			}
		}

		&__icon {
			font-size: 52px;
			color: #ffffff;
			margin-bottom: 20px;
			background-color: $cretech-black;
			display: flex;
			justify-content: center;
			align-items: center;
			width: 90px;
			height: 90px;
			flex-shrink: 0;
			border-radius: 50%;

			@media (min-width: 768px) {
				margin-bottom: 0;
				margin-right: 30px;
				margin-top: 4px;
			}
		}

		&__title {
			font-size: 24px;
			font-weight: bold;
			margin: 0;
			color: $cretech-primary;
			margin-bottom: 10px;

			@media (min-width: 768px) {
				margin-bottom: 0px;
			}

			a {
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: $cretech-black;
				}
			}
		}

		&__text {
			margin: 0;
		}
	}
}

.about-four {

	&__content {
		@media (min-width: 1200px) {
			margin-left: 70px;
		}
	}

	&__image {
		display: inline-block;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 30px;
			left: 0;
			bottom: 30px;
			right: -30px;
			background-image: linear-gradient(0deg, $cretech-secondary 0%, $cretech-primary 100%);
			display: none;

			@media (min-width: 768px) {
				display: block;
			}

			@media (min-width: 992px) {
				display: none;
			}

			@media (min-width: 1200px) {
				display: block;
			}
		}

		img {
			position: relative;
			max-width: 100%;
		}
	}

	&__text {
		margin-top: -20px;
		margin-bottom: 40px;
	}

	&__meta {
		border-top: 1px solid $cretech-border;
		padding-top: 30px;

		@media (min-width: 768px) {
			display: flex;
			align-items: center;
			justify-content: space-between;
			flex-wrap: wrap;
		}
	}

	&__author {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		@media (min-width: 768px) {
			margin-bottom: 0;
		}

		@media (min-width: 992px) {
			margin-bottom: 30px;
		}

		@media (min-width: 1200px) {
			margin-bottom: 0;
		}

		img {
			flex-shrink: 0;
			margin-right: 20px;
		}

		&__title {
			margin: 0;
			color: $cretech-black;
			letter-spacing: -0.025em;
			font-size: 20px;
			font-weight: bold;
		}

		&__designation {
			font-size: 14px;
			text-transform: uppercase;
			color: $cretech-primary;
			margin: 0;
			line-height: 1em;
			margin-top: 5px;
			font-weight: bold;
		}
	}
}

.about-five {

	&__images {
		position: relative;
		display: inline-block;

		&__shape {
			width: 503px;
			height: 366px;
			background-image: url(../images/shapes/about-five-1-1.png);
			position: absolute;
			bottom: -183px;
			left: -251.5px;
			display: none;

			@media (min-width: 1200px) {
				display: block;
			}
		}

		img {
			max-width: 100%;

			&:nth-of-type(2) {
				display: block;
				margin-left: auto;
				margin-top: -80px;
				position: relative;

				@media (min-width: 992px) {
					margin: 0;
					position: absolute;
					bottom: -25px;
					right: 25px;
				}

				@media (min-width: 1200px) {
					right: -110px;
				}
			}
		}

		&__caption {
			position: absolute;
			bottom: 30px;
			left: 0;
			width: 280px;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.33em;
			display: flex;
			align-items: flex-start;
			background-color: #fff;
			box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
			border-right: 10px solid $cretech-primary;
			padding-left: 44px;
			padding-right: 30px;
			padding-top: 29px;
			padding-bottom: 17px;
			border-radius: 55px;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;

			@media (min-width: 992px) {
				bottom: -55px;
				left: 90px;
			}

			span {
				font-weight: bold;
				line-height: 1;
				display: block;
				font-size: 72px;
				color: $cretech-secondary;
				margin-right: 20px;
				margin-top: -8px;
				letter-spacing: -0.05em;
			}
		}
	}

	&__video {
		width: 80px;
		height: 80px;
		background-color: $cretech-primary;
		box-shadow: 0px 0px 43px 0px rgba(0, 0, 0, 0.07);
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 55%;
		left: 30%;
		transform: translate(-50%, -50%);
		transition: all 500ms ease;
		color: #fff;

		@media (min-width: 992px) {
			top: 30px;
			right: 30px;
			transform: translate(0);
			left: auto;
		}

		@media (min-width: 1200px) {
			top: 80px;
			right: -40px;
		}

		&::before,
		&::after {
			content: '';
			position: absolute;
			border: 1px solid $cretech-border;
			z-index: -1;
		}

		&::before {
			width: 100px;
			height: 100px;
			top: calc(50% - 50px);
			left: calc(50% - 50px);
			animation: videoBtnAnim 3s ease-in-out infinite;
		}

		&::after {
			width: 140px;
			height: 140px;
			top: calc(50% - 70px);
			left: calc(50% - 70px);
			animation: videoBtnAnim 5s ease-in-out infinite;
		}


		&:hover {
			background-color: $cretech-black;
			color: #fff;
		}

		a {
			color: inherit;
			font-size: 20px;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			height: 100%;
		}
	}

	.section-title {
		padding-bottom: 30px;
	}

	&__text+&__text {
		margin-top: 20px;

		@media (min-width: 768px) {
			margin-top: 30px;
		}
	}

	&__box {
		@include reset-ul;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;
		margin-top: 20px;
		margin-bottom: 30px;

		@media (min-width: 576px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: 768px) {
			margin-top: 40px;
		}

		@media (min-width: 992px) {
			grid-template-columns: repeat(1, 1fr);
		}

		@media (min-width: 1200px) {
			grid-template-columns: repeat(2, 1fr);
		}

		&__item {
			padding: 26px 30px;
			display: flex;
			align-items: center;
		}

		&__icon {
			flex-shrink: 0;
			color: $cretech-black;
			font-size: 48px;
			margin-right: 20px;
		}

		&__title {
			color: $cretech-primary;
			margin: 0;
			font-size: 20px;
			font-weight: bold;
			line-height: 1.2em;
			margin-top: -3px;

			a {
				background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 2px no-repeat;
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: $cretech-primary;
					background-size: 100% 2px;
				}
			}
		}

		&__text {
			margin: 0;
			margin-bottom: -3px;
		}
	}
}

.about-six {
	background-image: url(../images/shapes/about-six-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: right 50%;

	.container>.row>[class*=col-]:nth-child(2) {
		@media (min-width: 1200px) {
			display: flex;
			justify-content: flex-end;
			align-items: flex-start;
		}
	}

	&__images {
		position: relative;
		display: inline-block;

		&::before {
			content: '';
			background-image: url(../images/shapes/about-six-dots-1-1.png);
			width: 370px;
			height: 370px;
			position: absolute;
			bottom: 0;
			left: 100%;
			margin-left: -185px;
			display: none;
			transform-origin: center bottom;
			animation-fill-mode: both;
			animation-name: bounce;
			animation-timing-function: ease-out;
			animation-duration: 5s;
			animation-iteration-count: infinite;

			@media (min-width: 992px) {
				display: block;
			}

			@media(min-width: 1200px) {
				left: 0;
				bottom: 63px;
				margin-left: -147px;
			}
		}

		&::after {
			content: '';
			width: 30px;
			height: 110px;
			background-color: $cretech-secondary;
			right: calc(100% + 30px);
			top: 0;
			position: absolute;
		}


		img {
			position: relative;
			max-width: 100%;

			&:nth-of-type(2) {
				margin-top: 30px;

				@media (min-width: 768px) {
					position: absolute;
					bottom: 0;
					right: 0;
				}

				@media (min-width: 1200px) {
					right: auto;
					left: -183px;
				}
			}
		}
	}

	&__text {
		margin-bottom: 43px;
		margin-top: -20px;
	}

	&__btns {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		margin-top: 50px;
	}

	&__link {
		display: inline-flex;
		align-items: center;
		text-transform: uppercase;
		color: $cretech-black;
		font-size: 14px;
		font-weight: bold;
		letter-spacing: .1em;
		margin-left: 15px;
		transition: all 500ms ease;

		@media (min-width: 768px) {
			margin-left: 30px;
		}

		&:hover {
			color: $cretech-primary;
		}

		i {
			margin-left: 5px;
		}
	}

	&__list {
		@include reset-ul;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;

		@media (min-width: 768px) {
			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: 1200px) {
			padding-right: 140px;
		}

		&__item {
			padding: 30px 40px;
			background-color: $cretech-gray;
			border-top: 4px solid $cretech-primary;
			padding-top: 26px;
		}

		&__icon {
			font-size: 24px;
			color: $cretech-secondary;
		}

		&__title {
			margin: 0;
			color: $cretech-black;
			font-size: 38px;
			font-weight: bold;
			letter-spacing: -0.025em;
			line-height: 1.33em;
			margin-top: 10px;
			margin-bottom: 4px;


			@media (min-width: 768px) {
				font-size: 42px;
			}

			@media (min-width: 992px) {
				font-size: 48px;
			}
		}

		&__text {
			letter-spacing: -0.025em;
			font-weight: 400;
			margin-bottom: -10px;
		}
	}
}

.about-seven {
	.section-title {
		padding-bottom: 30px;
	}

	&__text {
		margin-bottom: 40px;

		@media (min-width: 1200px) {
			padding-right: 44px;
		}
	}

	&__list {
		@include reset-ul;
		display: grid;
		grid-template-columns: repeat(1, 1fr);
		grid-gap: 30px;

		@media (min-width: 576px) {

			grid-template-columns: repeat(2, 1fr);
		}

		@media (min-width: 768px) {
			grid-template-columns: repeat(3, 1fr);
		}

		@media (min-width: 1200px) {
			padding-left: 7px;
		}

		&__item {
			background-color: #fff;
			position: relative;
			box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.1);
			transition: all 500ms ease;
			padding-top: 36px;
			padding-bottom: 29px;

			&:hover {
				background-color: $cretech-black;

				&::before {
					visibility: visible;
					opacity: 1;
					transform: translateY(0);
					transition-delay: 100ms;
				}
			}

			&::before {
				content: '';
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				visibility: hidden;
				background-image: url(../images/shapes/about-seven-list-bg-1-1.png);
				opacity: 0;
				transition: 500ms ease;
				background-repeat: no-repeat;
				transform: translateY(100px);
			}

			&::after {
				content: '';
				width: 16px;
				height: 16px;
				background-color: $cretech-primary;
				position: absolute;
				top: 0;
				left: 0;
				transform: scale(1, 1);
				transform-origin: left top;
				transition: transform 500ms ease;
			}

			&:hover::after {
				transform: scale(0, 1);
			}
		}

		&__icon {
			position: relative;
			font-size: 64px;
			color: $cretech-primary;
			transition: all 500ms ease;
		}

		&__item:hover &__icon {
			color: #fff;
		}

		&__title {
			position: relative;
			margin: 0;
			font-size: 20px;
			line-height: 1.2em;
			color: $cretech-black;
			margin-top: 17px;
			letter-spacing: -0.025em;

			a {
				background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 95% / 0px 2px no-repeat;
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: #fff;
					background-size: 100% 2px;
				}
			}
		}

		&__item:hover &__title a {
			color: #fff;
		}
	}
}