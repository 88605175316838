.footer-main {
	background-image: url(../images/background/footer-bg-1-1.png);
	background-repeat: no-repeat;
	background-position: left center;
	background-color: $cretech-black;
	background-blend-mode: luminosity;

	&--top-padding {
		padding-top: 160px;
		margin-top: -80px;

		@media (min-width: 992px) {
			padding-top: 240px;
			margin-top: -120px;
		}
	}
}

.footer-widget {

	&--links {
		@media (min-width: 1200px) {
			margin-left: 70px;
		}
	}

	&__logo {
		display: inline-block;
		margin-top: -8px;
	}

	&__text {
		margin: 0;
		color: $cretech-link-light;
	}

	&--about &__text {
		margin-top: 34px;
		margin-bottom: 20px;
	}

	&--newsletter &__text {
		font-weight: 400;
		color: $cretech-text-light;
	}

	&__title {
		color: #fff;
		font-size: 20px;
		font-weight: bold;
		position: relative;
		margin-top: -4px;
		margin-bottom: 34px;

		@media (min-width: 768px) {
			font-size: 24px;
		}

		&::after {
			content: '';
			width: 31px;
			height: 2px;
			display: block;
			background-color: $cretech-secondary;
			margin-top: 8px;
		}
	}

	&__social {
		@include reset-ul;
		display: flex;
		align-items: center;
		margin-left: -7px;
		margin-right: -7px;

		li {
			margin: 0 7px;

			a {
				width: 40px;
				height: 40px;
				border: 1px solid $cretech-primary;
				color: #fff;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				transition: all 500ms ease;
				border-radius: 50%;

				&:hover {
					color: #fff;
					background-color: $cretech-primary;
				}
			}
		}
	}

	&__links {
		@include reset-ul;
		margin-top: -2px;
		margin-bottom: -12px;

		li {
			font-size: 16px;
			line-height: 36px;
			color: $cretech-link-light;

			a {
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: #fff;
				}
			}
		}
	}

	&__contact {
		@include reset-ul;

		li {
			position: relative;
			padding-left: 33px;
			color: $cretech-link-light;
			font-size: 16px;
			line-height: 36px;

			>i {
				font-size: 16px;
				color: $cretech-secondary;
				position: absolute;
				top: 11px;
				left: 0;
			}

			a {
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: #fff;
				}
			}

		}
	}

	&__newsletter {
		margin-top: 21px;

		input[type=text],
		input[type=email] {
			width: 100%;
			padding-left: 20px;
			border: none;
			outline: none;
			background-color: #fff;
			color: $cretech-text;
			font-size: 14px;
			height: 50px;
			margin-bottom: 10px;
		}

		&__btn {
			padding: 10px 29px;
		}
	}
}

.footer-bottom {
	text-align: center;
	background-color: $cretech-bg-black;
	padding-top: 20px;
	padding-bottom: 20px;

	p {
		margin: 0;
		color: $cretech-link-light;
	}
}