.contact-one {
	&__form {
		padding-left: 15px;
		padding-right: 15px;
		padding-top: 80px;
		padding-bottom: 80px;
		background-color: $cretech-gray;

		@media (min-width: 768px) {
			padding: 80px 40px;
		}

		@media (min-width: 992px) {
			padding: 80px;
		}

		.row {
			--bs-gutter-y: 20px;
			--bs-gutter-x: 20px;
		}

		label {
			display: block;
		}

		input[type=text],
		select,
		.nice-select,
		input[type=email] {
			display: block;
			border: none;
			outline: none;
			background-color: #fff;
			width: 100%;
			padding-left: 30px;
			font-size: 16px;
			color: $cretech-text;
			height: 56px;
			border-radius: 0;
		}

		select {
			-moz-appearance: none;
			-webkit-appearance: none;
			appearance: none;
			background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="1024" height="1024" viewBox="0 0 1024 1024"><path fill="currentColor" d="M540.1 776.847l472.32-473.63c15.466-15.518 15.44-40.642-0.080-56.134-15.518-15.48-40.656-15.44-56.134 0.080l-444.208 445.438-444.206-445.454c-15.48-15.518-40.602-15.558-56.122-0.080-7.78 7.766-11.67 17.94-11.67 28.114 0 10.148 3.864 20.282 11.59 28.034l472.308 473.632c7.436 7.474 17.556 11.668 28.1 11.668s20.652-4.206 28.102-11.668z"></path></svg>');
			background-repeat: no-repeat;
			background-position: right 30px top 50%;
			background-size: .65em auto;
		}

		.nice-select span {
			display: flex;
			align-items: center;
			height: 100%;
			font-size: inherit;
			font-weight: 500;
			color: inherit;
		}

		.nice-select .list {
			width: 100%;
			border-radius: 0;

			li {
				text-transform: uppercase;
				color: $cretech-text;
				font-weight: 500;
				transition: all 500ms ease;
				display: block;
				padding: 2px 20px;

				&:hover {
					background-color: $cretech-black;
					color: #fff;
				}
			}
		}

		.nice-select:after {
			content: '\f107';
			font-family: "Font Awesome 5 Free";
			font-weight: 900;
			border: none;
			width: auto;
			height: auto;
			transform: rotate(0deg) translateY(-50%);
			top: 50%;
			right: 30px;
			color: $cretech-text;
			margin: 0;
			transform-origin: center center;
			font-size: 14px;
		}

		.nice-select.open:after {
			content: '\f106';

		}

		textarea {
			display: block;
			border: none;
			outline: none;
			background-color: #fff;
			width: 100%;
			padding-left: 30px;
			font-size: 16px;
			color: $cretech-text;
			height: 138px;
			padding-top: 20px;
		}
	}

	.container>.row>[class*=col-] {
		@media (min-width: 992px) {
			display: flex;
		}
	}

	&__info {
		position: relative;
		text-align: center;
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center center;
		padding-top: 80px;
		padding-bottom: 80px;

		@media (min-width: 992px) {
			margin-left: -30px;
			min-width: 400px;
		}

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			@include cretech-gradient();
			opacity: 0.93;
		}
	}

	&__item {
		position: relative;

		&__title {
			margin: 0;
			text-transform: uppercase;
			color: #fff;
			letter-spacing: .1em;
			font-size: 18px;
			font-weight: bold;
			line-height: 1.667em;
			margin-top: -9px;
		}

		&__text {
			color: rgba(255, 255, 255, 0.7);
			line-height: 1.75em;
			margin: 0;
			margin-top: 4px;

			a {
				background: linear-gradient(to right, currentcolor 0%, currentcolor 100%) 0px 80% / 0px 1px no-repeat;
				color: inherit;
				transition: all 500ms ease;

				&:hover {
					color: #fff;
					background-size: 100% 1px;
				}
			}
		}
	}

	&__item+&__item {
		margin-top: 38px;
	}

	&__social {
		@include reset-ul;
		display: flex;
		align-items: center;
		margin-left: -7px;
		margin-right: -7px;
		justify-content: center;

		li {
			margin: 0 7px;

			a {
				width: 40px;
				height: 40px;
				border: 1px solid #fff;
				color: #fff;
				font-size: 14px;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				transition: all 500ms ease;
				border-radius: 50%;

				&:hover {
					color: $cretech-black;
					background-color: #fff;
				}
			}
		}
	}
}

.contact-two {
	@include cretech-gradient;
	position: relative;
	z-index: 10;

	&::before {
		content: '';
		background-image: url(../images/shapes/contact-two-bg-1-1.png);
		background-position: top left;
		background-repeat: no-repeat;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}

	.container {
		position: relative;
	}

	&__video {
		width: 100px;
		height: 100px;
		background-color: #fff;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		font-size: 20px;
		color: $cretech-primary;
		transition: all 500ms ease-out;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		transition: 500ms ease;

		.ripple,
		.ripple:before,
		.ripple:after {
			position: absolute;
			top: 50%;
			left: 50%;
			width: 100px;
			height: 100px;
			transform: translate(-50%, -50%);
			box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.6);
			animation: ripple 3s infinite;
		}

		.ripple:before {
			animation-delay: 0.9s;
			content: "";
		}

		.ripple:after {
			animation-delay: 0.6s;
			content: "";
		}


		@media (min-width: 1200px) {
			transform: translate(0, 0);
			top: 0;
			left: -100px;
		}

		&:hover {
			background-color: $cretech-black;
			color: #fff;
		}
	}

	&__image {
		position: relative;
		display: inline-block;

		&::after {
			content: '';
			width: 30px;
			height: 100px;
			top: calc(100% + 140px);
			left: calc(100% + 20px);
			background-color: $cretech-primary;
			position: absolute;
		}

		img {
			max-width: 100%;

			@media (min-width: 1200px) {
				margin-bottom: -240px;
			}
		}
	}



	.contact-one__form {
		padding: 0;
		background-color: rgba(0, 0, 0, 0);
	}

	.contact-one__form input[type=text],
	.contact-one__form select,
	.contact-one__form .nice-select,
	.contact-one__form textarea,
	.contact-one__form input[type=email] {
		border: 1px solid rgba(255, 255, 255, 0.2);
		background-color: rgba(255, 255, 255, 0.1);
		color: #fff;
	}

	.contact-one__form textarea {
		height: 120px;
	}

	.contact-one__form .nice-select::after {
		color: #fff;
	}
}